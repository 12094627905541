import React from 'react'
import './animate.css'
import './custom.css'
import './style.default.css'
import logo from "../img/brand-b9660fd0fd0ba793b8b8beb38dcbad18b847981943d0061345615378312a8bc9.svg"
import * as firebase from 'firebase/app';
import QRCode from 'react-qr-code';
import "firebase/database";

class Resultado extends React.Component {

   constructor(props) {
     super(props);

     this.state = {
        id_transa: '',
     }
   }

   componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        if(query !== undefined && query !== null && query !== '') {
            var id = query.get('id_transacao');
            this.setState({ id_transa: id });
        } 
    }

    render() {
        return(
            <div style={{ width: '100vw', height: '100vh' }}>
                 <header class="header">
                 <nav class="navbar navbar-expand-lg fixed-top">
        <div class="container"><a href="index" class="navbar-brand"><img src={logo} alt="" class="img-fluid" /></a>
          <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler navbar-toggler-right">Menu<i class="fa fa-bars ml-2"></i></button>
          <div id="navbarSupportedContent" class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
                  
                  <li class="nav-item"> <a href="index" class="nav-link">Inicio</a></li>
                  
                  <li class="nav-item"> <a href="index#historia" class="nav-link">Desde 1992</a></li>
                  
                  <li class="nav-item"> <a href="sabores" class="nav-link active">Cardápio</a></li>
                  
                  <li class="nav-item"> <a href="loja" class="nav-link">Loja</a></li>
                  
                  <li class="nav-item"> <a href="#contacto" class="nav-link">Contato</a></li>

                </ul>
          </div>
        </div>
      </nav>
    </header>
     <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', backgroundColor: '#F8F8F8' }}>
       <QRCode value={this.state.id_transa} />
       <h2 style={{ marginTop: 20, fontWeight: 'bold', fontSize: 24, color: '#000' }}>Compra realizada com sucesso!</h2>
        <h2 style={{ fontWeight: '500' }}>Salve este QRCode para mostrar ao buscar o produto em nossa sorveteria.</h2>
     </div>
  </div>
        )
    }

}

export default Resultado;