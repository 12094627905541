import React from 'react'
import logo from "../img/brand-b9660fd0fd0ba793b8b8beb38dcbad18b847981943d0061345615378312a8bc9.svg"
import * as firebase from 'firebase/app';
import "firebase/database";
import { Modal } from 'react-bootstrap';
import Checkbox  from './Checkbox';
const mercadopago = require("mercadopago");

class Loja extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
       produtos: [],
       sabores: [],
       showModal: false,
       item_preco_selected: '',
       item_name_selected: '',
       item_desc_selected: '',
       item_foto_selected: '',
       showActionFilterList: false,
       checkedItems: new Map(),
       checkbox: [
        {
          name: 'Morango',
          key: 'checkBox1',
          label: 'Check Box 1',
        },
        {
          name: 'Chocolate',
          key: 'checkBox2',
          label: 'Check Box 2',
        },
        {
          name: 'Bacuri',
          key: 'checkBox3',
          label: 'Check Box 3',
        },
        {
          name: 'Cachorro',
          key: 'checkBox4',
          label: 'Check Box 4',
        },
        {
          name: 'Flocos',
          key: 'checkBox5',
          label: 'Check Box 5',
        },
        {
          name: 'Leão',
          key: 'checkBox6',
          label: 'Check Box 6',
        }
       ]
    }

    this.handleChange = this.handleChange.bind(this);
  }

  showList = () => this.setState(prevState => ({
    showActionFilterList: !prevState.showActionFilterList
}));  

handleChange(e) {
const item = e.target.name;
const isChecked = e.target.checked;
 if(this.state.checkedItems.size <= 4) {
   console.log(this.state.checkedItems.size);
   this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
 } else {
   alert('Você só pode selecionar 5 sabores');
 }
}

  getSabores() {
    var sabor = [];
    var sabores = firebase.database().ref('sabores');
    const self = this;
    sabores.on('value', function(snap) {
      snap.forEach((child) => {
         let resultado = {
            id: child.key,
            name: child.val().nome,
            key: child.val().nome,
         }

        sabor.push(resultado);
      })
      self.setState({ sabores: sabor });
    })

  }

  getProdutos() {
    var product = [];
    var produtos = firebase.database().ref('produtos');
    const self = this;
    produtos.on('value', function(snap) {
       snap.forEach((child) => {
          let result = {
             id: child.key,
             descricao: child.val().descricao,
             nome: child.val().nome,
             preco: child.val().preco,
             foto: child.val().foto,
          }

          product.push(result);
       })
       //console.log(product);
       self.setState({ produtos: product });
    })
  }

  componentDidMount() {
    this.getProdutos();
    this.getSabores();
  }

    render() {
        return(
            <body>
                <header class="header">
                <nav class="navbar navbar-expand-lg fixed-top">
        <div class="container"><a href="index.html" class="navbar-brand"><img src={logo} alt="" class="img-fluid" /></a>
          <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler navbar-toggler-right">Menu<i class="fa fa-bars ml-2"></i></button>
          <div id="navbarSupportedContent" class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
                  
                  <li class="nav-item"> <a href="index" class="nav-link">Inicio</a></li>
                  
                  <li class="nav-item"> <a href="index#historia" class="nav-link">Desde 1992</a></li>
                  
                  <li class="nav-item"> <a href="sabores" class="nav-link">Cardápio</a></li>
                  
                  <li class="nav-item"> <a href="https://cardapi.us/nutrisorv" class="nav-link active">Loja</a></li>
                  
                  <li class="nav-item"> <a href="index#contacto" class="nav-link">Contato</a></li>

                </ul>
          </div>
        </div>
      </nav>
    </header>
    <div style={{ display: 'flex', width: '100vw', height: '100vh' , backgroundColor: '#FFF', flexDirection: 'column' }}>
    <Modal
        size="lg"
        show={this.state.showModal}
        onHide={() => {
          this.setState({ showModal: false, checkedItems: new Map(), item_name_selected: '', item_preco_selected: '', item_foto_selected: '', item_desc_selected: '' })
        }}
        //onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
             <h1 style={{ fontSize: 20, color: '#000' }}>Comprar {this.state.item_name_selected}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <div style={{ display: 'flex', flexDirection: 'column' }}>
           <h2 style={{ fontWeight: 'bold', color: '#000', fontSize: 22, marginBottom: 20 }}>Escolha os sabores</h2>
         
        {
          this.state.sabores.map(item => {
            return(
            <label style={{ display: 'flex', flexDirection: 'row' }} key={item.key}>
               <Checkbox name={item.name} style={{ width: 100, height: 100 }} checked={this.state.checkedItems.get(item.name)} onChange={this.handleChange} />
               <h1 style={{ fontSize: 16, color: '#000', fontWeight: 'normal', marginLeft: 5 }}>{item.name}</h1>
            </label>
          )}
          )
        }
        <div className="hr-div" />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ borderStyle: 'solid', borderColor: '#000', borderWidth: .1 }}>
            <img className="img-produto" src={this.state.item_foto_selected} />
          </div>
          <div style={{ marginLeft: 10, flexDirection: 'column', marginTop: 5 }}>
            <h1 style={{ fontSize: 18, color: '#000', fontWeight: '500' }}>{this.state.item_name_selected}</h1>
            <h1 style={{ fontSize: 14, color: '#666', fontWeight: 'normal' }}>{this.state.item_desc_selected}</h1>
            <h1 style={{ fontSize: 18, color: '#666', fontWeight: '500' }}>R$ {this.state.item_preco_selected}</h1>
            </div>
            {/* <a href="https://pag.ae/7VYTcKyG4/button" target="_blank" title="Pagar com PagSeguro"><img src="//assets.pagseguro.com.br/ps-integration-assets/botoes/pagamentos/205x30-pagar.gif" alt="Pague com PagSeguro - é rápido, grátis e seguro!" /></a>*/}
            <button className="button-redirect" style={{ marginLeft: 'auto' }} onClick={() => { 
              window.location.href = "https://pag.ae/7VZmruhQv/button";
            }}>Continuar</button>
        </div>
        </div>
        </Modal.Body>
      </Modal>
   <div className="bg-loja">
     <h1 className="bg-loja-text">Loja</h1>
   </div>
      <div style={{ display: 'flex' }}>
        
      {this.state.produtos.map((item, index) => {
         return(
           <div style={{ justifyContent: 'center', alignItems: 'center' }}>
              <img src={item.foto} className="item-foto" />
               <center><h1 style={{ alignSelf: 'center', color: '#000', fontSize: 18 }}>{item.nome}</h1></center>
               <center><h1 style={{ alignSelf: 'center', color: '#666', fontSize: 14, fontWeight: '500' }}>{item.descricao}</h1></center>
               <center>
                 <button className="button-comprar" onClick={() => {
                   this.setState({ showModal: true, item_name_selected: item.nome, item_preco_selected: item.preco, item_foto_selected: item.foto, item_desc_selected: item.descricao })
                 }}>Comprar</button>

               </center>
            </div>
         )
      })}
     </div>
     </div>
    

            </body>
        )
    }
}

export default Loja;