import React from 'react'
import './animate.css'
import './custom.css'
import './style.default.css'
import logo from "../img/brand-b9660fd0fd0ba793b8b8beb38dcbad18b847981943d0061345615378312a8bc9.svg"
import * as firebase from 'firebase/app';
import "firebase/database";

class Sabores extends React.Component {

   constructor(props) {
     super(props);

     this.state = {
        lista_sabores: [],
     }
   }

   componentDidMount() {
     this.getSabores();
   }
   
   getSabores() {
      const self = this;
      var sabor = [];
      var sabores = firebase.database().ref('sabores');
      sabores.on('value', function(snap) {
         snap.forEach((child) => {
             let resultado = {
                id: child.key,
                nome: child.val().nome,
                descricao: child.val().descricao,
                imagem: child.val().imagem,
             }
             sabor.push(resultado);
         })
         self.setState({ lista_sabores: sabor });
      })
   }

    render() {
        return(
            <div style={{ width: '100vw', height: '100vh' }}>
                 <header class="header">
                 <nav class="navbar navbar-expand-lg fixed-top">
        <div class="container"><a href="index.html" class="navbar-brand"><img src={logo} alt="" class="img-fluid" /></a>
          <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler navbar-toggler-right">Menu<i class="fa fa-bars ml-2"></i></button>
          <div id="navbarSupportedContent" class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
                  
                  <li class="nav-item"> <a href="index" class="nav-link">Inicio</a></li>
                  
                  <li class="nav-item"> <a href="index#historia" class="nav-link">Desde 1992</a></li>
                  
                  <li class="nav-item"> <a href="sabores" class="nav-link active">Cardápio</a></li>
                  
                  <li class="nav-item"> <a href="https://cardapi.us/nutrisorv" class="nav-link">Loja</a></li>
                  
                  <li class="nav-item"> <a href="index#contacto" class="nav-link">Contato</a></li>

                </ul>
          </div>
        </div>
      </nav>
    </header>
            <div class="bg-dark" id="Sabores">
    <div class="container text-left text-sm-center text-md-left">    
      <div class="row col-lg-6">
      <div class="wow fadeInDown" data-wow-delay="0.3s"><h1>Cardápio</h1></div>
        <div class="wow fadeInUp" data-wow-delay="0.3s"><h2>Conheça os sabores incríveis da nossa sorveteria.<br/><br/><br/></h2>
        </div>
      </div>
        
        <div class="integrations mt-12">
          <div class="row">

            {
              this.state.lista_sabores.map((item, index) => {
                 return(
                  <div key={index} class="col-lg-4 col-md-6 col-sm-6 d-none d-sm-block">
                  <div class="box text-center wow fadeInRight" data-wow-delay="0.3s">
                    <div><img src={item.imagem} alt="..." class="img-fluid" width="100%" height="210" width="210" /></div> 
                    <h4 style={{ color: '#f62948' }}>{item.nome}</h4>
                    <p class="font-weight-light">{item.descricao}</p>
                  </div>
                </div>
                 )
              })
            }
            
           

        </div>
              {/*<div class="col-lg-12 text-center">
                  <button id="sendMessageButton" class="btn btn-primary btn-xl text-uppercase" type="submit">+ SABORES</button>
          </div>*/}
            </div>
    </div>
  </div> 
  </div>
        )
    }

}

export default Sabores;