import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Index from './index/index.jsx'
import Sabores from './index/sabores.jsx';
import Login from './index/sabores.jsx';
import Loja from './index/loja.jsx';
import * as firebase from 'firebase/app';
import "firebase/auth";
import Resultado from './index/resultado.jsx';

var firebaseConfig = {
    apiKey: "AIzaSyBIKiO-XgB_HF8FO98FuXoHMW0VhQH2zJY",
    authDomain: "nutrisorv-aef4e.firebaseapp.com",
    databaseURL: "https://nutrisorv-aef4e.firebaseio.com",
    projectId: "nutrisorv-aef4e",
    storageBucket: "nutrisorv-aef4e.appspot.com",
    messagingSenderId: "1049011751664",
    appId: "1:1049011751664:web:1bae9ce567c28275ca1035",
    measurementId: "G-ZGZZWDJYN0"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

const Routes = () => (
    <BrowserRouter>
       <Switch>
           <Route exact path="/" component={Index} />    
           <Route exact path="/index" component={Index} />    
           <Route exact path="/sabores" component={Sabores} />  
           {/*<Route exact path="/index/admin" component={Login} /> */} 
           {/* <Route exact path="/loja" component={Loja} /> */}
           {/* <Route path="/loja/resultado" component={Resultado} /> */}          
       </Switch>
    </BrowserRouter>
);

export default Routes;