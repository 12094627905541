import React from 'react'
import './animate.css'
import './custom.css'
import './style.default.css'

import typocentro from '../img/typocentro.png';
import centro from "../img/centro.png";
import helado from '../img/helado.png';
import logo from "../img/brand-b9660fd0fd0ba793b8b8beb38dcbad18b847981943d0061345615378312a8bc9.svg"

class Index extends React.Component {

    constructor(props) {
       super(props);
       
       this.state = {
          fotos_insta: []
       }
    }

    getFotosInsta() {
      var fotos = [];
      fetch('https://www.instagram.com/nutrisorv/?__a=1')
      .then(response => {
         response.json().then(resposta => {
          if( resposta.graphql.user.edge_owner_to_timeline_media.edges != undefined ) {
            var posts = resposta.graphql.user.edge_owner_to_timeline_media.edges;
            console.log(posts);
            for(var i = 0; i < 6; i++) {
              let result = {
                 foto: resposta.graphql.user.edge_owner_to_timeline_media.edges[i].node.display_url,
                 likes: resposta.graphql.user.edge_owner_to_timeline_media.edges[i].node.edge_liked_by.count,
              }
              fotos.push(result);
             }
             this.setState({ fotos_insta: fotos });
           }
         })
      }) 
    }

    componentDidMount() {
       this.getFotosInsta();
    }
     
    render() {
        return(
            <body>
     <header class="header">
     <nav class="navbar navbar-expand-lg fixed-top">
        <div class="container"><a href="index" class="navbar-brand"><img src={logo}  alt="" class="img-fluid" /></a>
          <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler navbar-toggler-right">Menu<i class="fa fa-bars ml-2"></i></button>
          <div id="navbarSupportedContent" class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
                  
                  <li class="nav-item"> <a href="index" class="nav-link active">Inicio</a></li>
                  
                  <li class="nav-item"> <a href="#historia" class="nav-link">Desde 1992</a></li>
                  
                  <li class="nav-item"> <a href="sabores" class="nav-link">Cardápio</a></li>
                  
                  <li class="nav-item"> <a href="https://cardapi.us/nutrisorv" class="nav-link">Loja</a></li>
                  
                  <li class="nav-item"> <a href="#contacto" class="nav-link">Contato</a></li>

                </ul>
          </div>
        </div>
      </nav>
    </header>
    <div class="principal" id="Home">
       

    

</div>  
<a class="ir-arriba" title="Volver arriba">
    <span class="fa-stack">
      <i class="fa fa-circle fa-stack-2x"></i>
      <i class="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
    </span>
</a>
  
  <div class="nuestroshelados" >
        <div class="container">
          <div class="row align-items-left" >
            <div class="col-md-6">
              <div class="wow fadeInDown" data-wow-delay="0.3s"><h1>DELIVERY NUTRI SORV<br/></h1></div>
              <div class="wow fadeInUp" data-wow-delay="0.3s"><h2 style={{ color: '#ab9fa3' }}>A Nutri Sorv está com delivery para continuar atendendo os clientes com muito amor e carinho.</h2></div>
                <div class="form-group">
                <a href="https://cardapi.us/nutrisorv" class="btn btn-primary mt-4">Vistar Loja</a>
                </div>
           </div>

           
                <div class="col-md-6">
                   {/* <div class="img123"> <img src={helado} Alt="helado" style={{ maxWidth: '100%', height: 'auto' }} /> */}
                    </div>
              
                </div>  
         </div>
      </div>


<div class="bg-local" id="historia">
  <div class="container text-left text-sm-center text-md-left">
    <div class="row align-items-center">
      <div class="col-lg-7">
          <div class="wow fadeInDown" data-wow-delay="0.3s"><h1>DESDE<br/>1992</h1></div>
            <div class="row wow fadeInUp" data-wow-delay="0.3s">
              <div class="col-lg-10">
                 
                  <div class=""></div><h2 style={{ color: '#FFF' }}><p style={{ color: '#FFF', textShadow: '-2px 2px 3px hsl(325, 88%, 10%)' }}>Todo bairro precisa de uma sorveteria, não é mesmo?! Então, em 1992 o bairro saci, localizado
em Teresina Piauí recebia sua primeira sorveteria, a Nutri Sorv, o nome surgiu da ideia de
oferecer um sorvete nutritivo. Pouco tempo depois a Nutri Sorv começou a deixar de apenas
vender para fabricar seu próprio sorvete, de forma artesanal. Isso mesmo, depois de cursos e
aprimoramento das receitas, surgiu um sorvete bastante cremoso, a base de leite com fruta de
verdade por isso tem valores nutricionais e a melhor matéria prima pra agradar o paladar mais
exigente.</p>
<p style={{ color: '#FFF', textShadow: '-2px 2px 3px hsl(325, 88%, 10%)' }}>Ah mas não é só de sorvete que nossa sorveteria existe, fabricamos outros produtos que são
vendidos na nossa sorveteria como nossa casquinha - crocante e gostosa, brownie, petit
gateau, salada de frutas, tudo para garantir a qualidade que nossos clientes merecem.
Falando nos nossos clientes, são os melhores do mundo, pois tem uma ligação e uma memória
afetiva com nossa marca, construída ao longo dos anos através do nosso produto de
qualidade, atendimento afetivo e respeitando todos os padrões de higiene exigidos, para
proporcionar experiências de valor e compartilhar todo o amor que colocamos em nosso
produto.</p></h2>
                
                
               </div>
            </div>
            
            {/* <div class="form-group">
            <a href="#" class="btn btn-primary mt-4">+ Info</a>
        </div> */}
          </div>
    </div>
    </div>
</div>


  {/* <div class="bg-uva" id="Novedades">
  <div class="container text-left text-sm-center text-md-left">
    <div class="row align-items-center">
      <div class="col-lg-7">
          <div class="wow fadeInDown" data-wow-delay="0.3s"><h1>Novidades</h1></div>
        <div class="row">
          <div class="col-lg-11 col-sm-11 wow fadeInUp" data-wow-delay="0.3s">
            <h2 style={{ color: '#ab9fa3' }}>La linea Summer Collection continúa innovando y presentando nuevas tendencias de sabores e ingredientes modernos y deliciosos para nuestros exclusivos clientes.</h2>
        </div><div><a href="detalle.html" class="btn btn-primary mt-4">+ Info</a></div>
      </div>
    </div>
  </div>
  <br/>
  <br/>
  </div>
      </div> */}

  <div class="bg-galeria">
    <div class="gallery-block compact-gallery">
       <div class="instagram">
           <div class="heading">
                   <div class="text-center">
                       <div class="text-center">
                           <h3 style={{ color: '#f62948' }}>Siga-nos Instagram!</h3>
                       </div>
                           <a href="http://www.instagram.com">@nutrisorv</a><br/><br/>
                        </div>
           </div>
           <div class="row no-gutters">
             {this.state.fotos_insta.map((item, index) => {
                return(
                  <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 item zoom-on-hover wow fadeInUp" data-wow-delay="0.2s" style={{ paddingRight: 0, paddingLeft: 0 }}>
                  <a class="lightbox" href="img/bg-img/11.jpg">
                      <img class="img-fluid image" src={item.foto} />
                      <span class="description">
                              <i class="fa fa-instagram fa-3x"></i>
                          <span class="description-heading">{item.likes}</span>
                      </span>
                  </a>
                </div>
                )
             })}
              
             
             
             
              
           </div>
       </div>
     </div>
    </div>

    <div class="bg-contacto d-none d-lg-block" id="contacto">
      <div class="container text-left text-sm-center text-md-left ">
        <div class="section-header">
          <h1>CONTATO</h1>
          <h2>Entre em contato com nossa equipe! Será um prazer te atender</h2>
        </div>
        <div class="row align-items-center mt-5">
          <div class="col-lg-7 ">
            <form action="#" class="contact-form text-left">
              <div class="form-group mb-4">
                <label>Nome<sup class="text-primary">&#10033;</sup></label>
                <input type="text" name="name" placeholder="Fulano de Tal" class="form-control" />
              </div>
              <div class="form-group mb-4">
                <label>E-mail<sup class="text-primary">&#10033;</sup></label>
                <input type="text" name="email" placeholder="nome@gmail.com" class="form-control" />
              </div>
              <div class="form-group mb-4">
                <label>Assunto<sup class="text-primary">&#10033;</sup></label>
                <input type="text" name="subject" placeholder="Qual o assunto?" class="form-control" />
              </div>
              <div class="form-group mb-4">
                <label>Mensagem<sup class="text-primary">&#10033;</sup></label>
                <textarea name="message" placeholder="O que acontece?" class="form-control"></textarea>
              </div>
              <div class="form-group">
                <input type="submit" value="Enviar Mensagem" class="btn btn-primary mt-4" />
              </div>
            </form>
          </div>
          <div class="col-lg-5 contact-details mt-5 mt-lg-0 d-none d-lg-block text-center">
            <div class="box">
              <div class="icon d-flex align-items-center"><img src="img/placeholder.svg" alt="" class="img-fluid" style={{ margin: 'auto', width: '15%' }} /></div>
              <h5 style={{ color: '#4b384a' }}>Localização</h5>
              <p class="text-small font-weight-light">R. Q. Setenta e Seis, 76 - Saci, Teresina - PI</p>
            </div>
            <div class="box">
              <div class="icon d-flex align-items-center"><img src="img/technology.svg" alt="" class="img-fluid" style={{ margin: 'auto', width: '15%' }} /></div>
              <h5 style={{ color: '#4b384a' }}>Contato</h5>
              <p class="text-small font-weight-light">Horário de funcionamento de terça à domingo das 14h até 22h. </p><strong class="text-muted">(86) 3304-5793</strong>
            </div>
            <div class="box">
              <div class="icon d-flex align-items-center"><img src="img/multimedia.svg" alt="" class="img-fluid" style={{ margin: 'auto', width: '15%' }} /></div>
              <h5 style={{ color: '#4b384a' }}>Contato</h5>
              <p class="text-small font-weight-light">Entre em contato pelo nosso e-mail, será um prazer lhe responder.</p>
              <ul class="text-center">
                <li style={{ color: '#e7475e' }}><a href="mailto:contato@nutrisorv.com.br" class="text-small">contato@nutrisorv.com.br</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    
</body>
        )
    }
}
export default Index;